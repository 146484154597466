import BuyButtonSticky from "../../components/shared/BuyButtonSticky";
import routes from "../../routes/routes";
import {
  Swiper,
  SwiperSlide
} from "../../../node_modules/swiper/react/swiper-react.js";
import "../../../node_modules/swiper/swiper.scss";
import "../../../node_modules/swiper/modules/navigation/navigation.scss"; // Navigation module
import "../../../node_modules/swiper/modules/effect-cards/effect-cards.scss"; // Pagination module
import "../../../node_modules/swiper/modules/effect-creative/effect-creative.scss"; // Pagination module
import "../../../node_modules/swiper/modules/autoplay/autoplay.scss"; // Pagination module
import "../../../node_modules/swiper/modules/free-mode/free-mode.scss"; // Free mode module
import "../../../node_modules/swiper/modules/a11y/a11y.scss"; // A11 mode module
import { Autoplay, A11y } from "swiper";

const Artist = () => {
  const imageNames = Array.from(
    { length: 10 },
    (_, index) => `logo_0${index + 1}.png`
  );

  return (
    <div id="theArtist">
      {/* HERO */}
      <section className="hero">
        <img
          className="w-100 img-fluid hero-image"
          src="/images/artist_01.jpeg"
          alt="the_artist"
        />
      </section>

      <div className="wrapper">
        {/* SECTION THE ARTIST */}
        <section className="theArtist">
          <div />
          <div className="container artist-container mh-100 py-4">
            <div className="theArtist__Grid">
              <div className="theArtist__Grid_box text-light">
                <h1 className="artist-name">EDGARDO GIMÉNEZ</h1>
                <p className="artist-description">
                  Edgardo Giménez es uno de los artistas latinoamericanos más
                  importantes del mundo.
                </p>
                <a
                  href="https://fancymonas.io/deck_proyecto.pdf"
                  className="btn button-artist-page"
                  target="_blank"
                  rel="noreferrer"
                >
                  Descargar el deck del proyecto
                </a>
              </div>
              <div className="theArtist__Grid_box">
                <img
                  className="w-100"
                  src="/images/artist_02.png"
                  alt="the_artist"
                />
                <p className="captions-image w-100 text-light">
                  Edgardo posado delante la reconstrucción de la escenografía
                  que realizó para el film Psexoanalisis (1967), en la feria
                  ArteBA (2010).
                </p>
              </div>
              <div className="theArtist__Grid_box">
                <img
                  className="w-100"
                  src="/images/artist_04.png"
                  alt="the_artist"
                />
                <p className="captions-image w-100 text-light">
                  Edgardo posando junto a su escultura Mona con banana.
                </p>
              </div>
              <div className="theArtist__Grid_box">
                <img
                  className="w-100"
                  src="/images/artist_03.jpeg"
                  alt="the_artist"
                />
                <p className="captions-image w-100 text-light">
                  Mono vestido, 1967-2004, aluminio policromado, altura 65cm.
                  Colección Claudia Kohen.
                </p>
              </div>
              <div className="theArtist__Grid_box">
                <img
                  className="w-100"
                  src="/images/artist_05.png"
                  alt="the_artist"
                />
                <p className="captions-image w-100 text-light">
                  ¿Por qué son tan geniales?, 1965, Giménez, Puzzovio, Squirru.
                  Póster panel via pública en Florida y Viamonte, Buenos Aires,
                  10 x 8m.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* LOGOS */}
        <section className="w-100">
          <div className="carrousel-logos container d-flex flex-column justify-content-center align-items-center">
            <div className="w-75 text-center d-flex flex-column justify-content-center align-items-center">
              <p className="carrousel-logos-title text-light">
                La obra de Edgardo Giménez forma parte de más de 20 museos y
                colecciones a nivel global.
              </p>
            </div>

            <Swiper
              a11y={false}
              speed={3100}
              spaceBetween={40}
              autoplay={{ delay: 1100, disableOnInteraction: false }}
              loop={true}
              modules={[Autoplay, A11y]}
              className="mySwiper-items w-100 trusted-by-swiper"
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                660: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                760: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                860: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                },
                960: {
                  slidesPerView: 4,
                  slidesPerGroup: 4
                },
                1060: {
                  slidesPerView: 5,
                  slidesPerGroup: 5
                }
              }}
            >
              {imageNames?.map((img, id) => (
                <SwiperSlide key={id}>
                  <img src={`/images/logos/${img}`} alt="moma" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>

        {/* SECTION ABOUT ARTIST */}
        <section id="about-artist" className="w-100 container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h2 className="about-artist w-75">
              ¿Quieres conocer más sobre Edgardo Giménez?
            </h2>
            <a
              href="http://www.edgardogimenez.com.ar/"
              className="btn button-artist-page"
              target="_blank"
              rel="noreferrer"
            >
              ir a la web de Edgardo Giménez
            </a>
          </div>
        </section>
      </div>

      {/* BUTTON STICKY CHECKOUT */}
      {/* <div className="d-lg-none d-xl-none d-xxl-none">
        <BuyButtonSticky
          link={`${routes.fancy_checkout}/inicio`}
          button={"Comprar Fancy Monas"}
          author={"by Edgardo Giménez"}
          icon
        />
      </div> */}
    </div>
  );
};

export default Artist;
