import React, { useRef, useState, useEffect } from "react";
import { AppContext } from "../../App";
import ModalContainer from "../../components/modals/ModalContainer";
import Login from "../../components/shared/Login";
import { login } from "../../services/axiosService";
import { getRandomMona } from "../../services/homeMonasService";
import iconRandom from "../../assets/home/icon-random.png";
import BuyButtonSticky from "../../components/shared/BuyButtonSticky";
import routes from "../../routes/routes";

export default function HomePage() {
  const { loginModal } = React.useContext(AppContext);
  const [printMona, setPrintMona] = useState();
  const mona = getRandomMona();

  const divRef = useRef(null);
  const btnRandomHandle = () => {
    const getMona = getRandomMona();
    const divEl = divRef.current;
    divEl.style.background = `${getMona.color};`;
    setPrintMona(getMona.img);
  };

  return (
    <>
      {
        <ModalContainer>
          {loginModal ? <Login handleLogin={login} /> : null}
        </ModalContainer>
      }
      <div className="fixed-home">
        <div style={{ background: mona.color, height: "100%" }}>
          <div
            ref={divRef}
            className="home-page fixed"
            style={{
              backgroundImage: `url(${printMona ?? mona.img})`
            }}
          />
          <button className="button-random" onClick={btnRandomHandle}>
            <img src={iconRandom} alt="random" />
          </button>
          {/* BUTTON STICKY CHECKOUT */}
        </div>
      </div>
      {/*  <div className="d-lg-none d-xl-none d-xxl-none">
        <BuyButtonSticky
          link={`${routes.fancy_checkout}/inicio`}
          button={"Comprar Fancy Monas"}
          author={"by Edgardo Giménez"}
          icon
        />
      </div> */}
    </>
  );
}
