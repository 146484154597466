import { Link, useNavigate } from "react-router-dom";
import BuyButtonSticky from "../../components/shared/BuyButtonSticky";
import routes from "../../routes/routes";
import ArrowDiagonal from "../../components/icons/ArrowDiagonal";

const Archive = () => {
  const imageNames = Array.from(
    { length: 24 },
    (_, index) => `archivo_0${index + 1}.png`
  );
  const navigate = useNavigate();

  return (
    <div id="archive">
      <div className="archive-wrapper">
        <div />
        <div>
          <section className="container hero bg-white d-flex justify-content-center align-items-center flex-column">
            <h1 className="archive-title">
              Cada Fancy Mona es un reflejo del universo estético de Edgardo
              Giménez
            </h1>
            <p className="archive-description">
              Las obra fue generada algorítmicamente luego de un año de
              investigación e interpretación de más de 200 piezas del archivo
              del artista.
            </p>
          </section>
          <section className="wrapper-images">
            {imageNames.map((imageName, index) => (
              <div
                key={index}
                className="box"
                onClick={() => {
                  const anchoPantalla = window.innerWidth;
                  if (anchoPantalla < 992) {
                    navigate(`${routes.archive_detail}/${index + 1}`);
                  }
                }}
              >
                <img
                  key={imageName}
                  src={`/images/archive_order/${imageName}`}
                  alt="archive"
                />
                <div className="overlay" />
                <div className="view-detail">
                  <Link to={`${routes.archive_detail}/${index + 1}`}>
                    <button className="button-detail">Ver más</button>
                  </Link>
                </div>
                <Link to={`${routes.archive_detail}/${index + 1}`}>
                  <div className="icon-arrow d-lg-none d-xl-none">
                    <ArrowDiagonal />
                  </div>
                </Link>
              </div>
            ))}
          </section>
        </div>
      </div>
      {/* BUTTON STICKY CHECKOUT */}
      {/* <div className="d-lg-none d-xl-none d-xxl-none">
        <BuyButtonSticky
          link={`${routes.fancy_checkout}/inicio`}
          button={"Comprar Fancy Monas"}
          author={"by Edgardo Giménez"}
          icon
        />
      </div> */}
    </div>
  );
};

export default Archive;
