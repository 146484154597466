import { useTranslation } from "react-i18next";
import InputSearchMonas from "./InputSearchMonas";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import CheckIcon from "../icons/CheckIcon";
import { Dropdown } from "react-bootstrap";

export default function Navbar() {
  const navigate = useNavigate();
  const isUser = localStorage.getItem("token");

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const handleClick = () => {
    navigate(`${routes.my_coleccion}`);
  };
  const handleCloseSession = () => {
    localStorage.removeItem("token");
    navigate(`${routes.home}`);
  };

  return (
    <div className="navbar-top-desktop">
      <InputSearchMonas />
      {/* <button
        className="btn-navbar-top"
        onClick={() => navigate(`${routes.fancy_checkout}/inicio`)}
      >
        <img src="/images/icons/shopping-cart.svg" alt="icon-cart" />
        Comprar Fancy Monas
      </button> */}
      {isUser && (
        <Dropdown className="d-inline">
          <Dropdown.Toggle
            id="dropdown-autoclose-true"
            className="btn-navbar-top-profile"
          >
            <CheckIcon color="#000" />
            Mi Perfil
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item href="#" onClick={handleClick}>
              Mi colección
            </Dropdown.Item>
            <Dropdown.Item onClick={handleCloseSession}>
              Cerrar sesión
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}
